<template>
  <div class="myStudyBox">
    <!-- <div class="myStudySearch">
      <el-input
        class="searchInput"
        v-model="getParams.key"
        placeholder="请输入内容"
        clearable
        @clear="searchFun"
        @keyup.enter.native="searchFun"
      ></el-input>
      <div class="search c_p" @click="searchFun">查询</div>
    </div> -->
    <div class="studyList" v-if="!isNull">
      <div v-for="(item, index) in dataList" :key="index">
        <div class="studyItem" v-if="item.list.length > 0">
          <p class="f18 f0404 f_b mt30">{{ item.label }}</p>
          <div class="studyChildList mt20">
            <div
              @click="goLink(childItem.pid)"
              class="childItem"
              v-for="(childItem, childIndex) in item.list"
              :key="childIndex"
              style="position: relative"
            >
              <div class="itemImg">
                <el-image :src="childItem.img" lazy></el-image>
              </div>
              <div class="mt10">
                <p class="c_33 f14 f_b txt-over">{{ childItem.title }}</p>
                <div class="df_Yc mt8">
                  <img
                    class="phoneIcon"
                    src="@/assets/image/icon/phone-icon.png"
                    alt=""
                  />
                  <p class="f12 c_bf txt-over">
                    观看至{{ childItem.section_title }}
                  </p>
                </div>
                <!-- <div class="percentage mt10">
                  <p class="scheduleTxt">学习进度{{ childItem.percentage }}</p>
                  <p class="c_f46 f12 c_p c_b" @click.stop="comment(childItem)">
                    发表评论
                  </p>
                </div> -->
              </div>
              <div class="hoverBox">
                <img src="../../assets/image/my/play.png" alt="" />
                <p>继续播放</p>
              </div>
              <div class="hoverDel" @click.stop="del(childItem.id)">
                <img src="../../assets/image/my/del.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>

    <!-- 不能评论弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="test"
      :show-close="false"
      center
    >
      <div class="popBox">
        <p class="t_c c_00 f16 f_b popTitle">评论提示</p>
        <p class="mt25 c_99 f14 t_c">很抱歉，您的该课程观看不足30%</p>
        <p class="mt10 c_99 f14 t_c">不能对该视频进行评价</p>
      </div>
      <div class="closeIcon" @click="dialogVisible = false">
        <i class="iconfont">&#xe659;</i>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from "../../utils/auth";
import { myWatchRecord, delWatchRecord } from "@/Api";
export default {
  name: "MyStudy",
  data() {
    return {
      dialogVisible: false,
      studyList: [],
      dataList: [
        {
          label: "今天",
          list: [],
        },
        {
          label: "昨天",
          list: [],
        },
        {
          label: "一周前",
          list: [],
        },
        {
          label: "一月前",
          list: [],
        },
        {
          label: "更早",
          list: [],
        },
      ],
      getParams: {
        token: getToken(),
      },
      isNull: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    del(id) {
      this.$confirm("此操作将永久删除该学习记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delWatchRecord({ token: getToken(), id: id }).then((res) => {
          if (res.data.message == "删除成功")
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
        });
      });
    },
    goLink(id) {
      this.$router.push({ path: "/course/detail", query: { detailId: id } });
    },
    //评论
    comment(data) {
      let num = parseInt(data.percentage);
      if (num > 30) {
        this.$commentPop({
          pid: data.pid,
          picture: data.picture,
          workTitle: data.workTitle,
        });
      } else {
        this.dialogVisible = true;
      }
    },
    //搜索
    searchFun() {
      this.getParams.page = 1;
      this.getList();
    },
    //获取学习列表(所有)
    getList() {
      myWatchRecord(this.getParams).then((res) => {
        this.studyList = res.data;
        if (Object.keys(this.studyList).length > 0) {
          this.isNull = false;
        }
        if (this.studyList.today && this.studyList.today.length > 0) {
          this.dataList[0].list = this.studyList.today;
        }
        if (this.studyList.yesterday && this.studyList.yesterday.length > 0) {
          this.dataList[1].list = this.studyList.yesterday;
        }
        if (this.studyList.week && this.studyList.week.length > 0) {
          this.dataList[2].list = this.studyList.week;
        }
        if (this.studyList.month && this.studyList.month.length > 0) {
          this.dataList[3].list = this.studyList.month;
        }
        if (this.studyList.earlier && this.studyList.earlier.length > 0) {
          this.dataList[4].list = this.studyList.earlier;
        }
      });
    },
    //  处理列表（按时间分组）
    processingData() {
      if (this.studyList.length === 0) {
        this.isNull = true;
        return;
      }
      this.dataList = this.$options.data().dataList;
      //当前时间（时间戳）
      const currentDate = new Date().getTime();
      //今天00:00:00 （时间戳）
      const todayStartDate = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      ).getTime();
      //昨天00:00:00（时间戳）
      const yesterdayStartDate = todayStartDate - 86400000;
      //距离前一周的00:00:00（时间戳）
      const lastWeekStartDate = todayStartDate - 86400000 * 7;
      //距离前一月的00:00:00（时间戳）
      const latsMonthStartDate = todayStartDate - 86400000 * 30;
      this.studyList.forEach((item) => {
        const dateTime = new Date(item.insertTime).getTime();
        if (todayStartDate <= dateTime && dateTime <= currentDate) {
          // 今天观看的
          this.dataList[0].list.push(item);
        } else if (
          yesterdayStartDate <= dateTime &&
          dateTime < todayStartDate
        ) {
          // 昨天观看的
          this.dataList[1].list.push(item);
        } else if (
          lastWeekStartDate <= dateTime &&
          dateTime < yesterdayStartDate
        ) {
          //前一周观看的
          this.dataList[2].list.push(item);
        } else if (
          latsMonthStartDate <= dateTime &&
          dateTime < lastWeekStartDate
        ) {
          //前一月观看的
          this.dataList[3].list.push(item);
        } else {
          // 更早
          this.dataList[4].list.push(item);
        }
      });
      this.studyList = [];
    },
  },
};
</script>
<style scoped>
.closeIcon {
  width: 0.3rem;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  background: #ffffff;
  right: 0;
  cursor: pointer;
}
.closeIcon i {
  font-size: 0.3rem;
  color: #5c5c5c;
}
.popTitle {
  margin-top: 1.1rem;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog {
  width: 4rem !important;
  height: 3.09rem !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 30%) !important;
}
::v-deep .el-dialog,
.el-pager li {
  background: url("../../assets/image/bg/pop-bg.png") no-repeat !important;
  background-size: 100% 100% !important;
}
.percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.studyChildList {
  margin-bottom: -0.3rem;
  margin-right: -0.2rem;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.studyChildList::after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
@keyframes myfirst {
  from {
    background: red;
  }
  to {
    background: yellow;
  }
}
.childItem {
  width: 22%;
  /* border-bottom: 0.04rem solid #eeeeee; */
  padding-bottom: 0.1rem;
  margin-bottom: 0.3rem;
  margin-right: 0.29rem;
  float: left;
  cursor: pointer;
  -webkit-transition: 500ms ease-out;
  -moz-transition: 500ms ease-out;
  -o-transition: 500ms ease-out;
  transition: 500ms ease-out;
}
/* .childItem:hover {
  border-bottom: 0.04rem solid #f46600;
} */
.itemImg {
  width: 2.4rem;
  height: 1.44rem;
  overflow: hidden;
  border-radius: 0.04rem;
}
.itemImg {
  width: 100%;
}
.phoneIcon {
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.08rem;
}
.scheduleTxt {
  color: #666666;
  font-size: 0.12rem;
  /* margin-right: 0.1rem; */
}
.myStudyBox {
  min-height: 9rem;
  background: #ffffff;
  border-radius: 0.04rem;
  overflow: hidden;
  padding: 0.3rem;
}
.searchInput {
  width: 1.8rem;
}
.myStudySearch >>> .el-input__inner {
  height: 0.32rem;
  line-height: 0.32rem;
}
.myStudySearch >>> .el-input__icon {
  line-height: 0.32rem;
}
.myStudySearch >>> .el-input {
  font-size: 0.14rem;
}
.search {
  background: #f46600;
  color: #ffffff;
  font-size: 0.14rem;
  width: 0.68rem;
  height: 0.32rem;
  line-height: 0.32rem;
  text-align: center;
  border-radius: 0.04rem;
  margin-left: 00.2rem;
}
.myStudySearch {
  display: flex;
  align-items: center;
}

.childItem:hover .hoverBox {
  visibility: visible;
}
.childItem:hover .hoverDel {
  visibility: visible;
}
.hoverBox {
  height: 0.7rem;
  visibility: hidden;
  position: absolute;
  top: 0.67rem;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.hoverDel {
  visibility: hidden;
  position: absolute;
  top: 0.08rem;
  right: 0.08rem;
  z-index: 1;
}

.hoverDel img {
  width: 16px;
  height: 16px;
}

.hoverBox img {
  width: 0.4rem;
  height: 0.4rem;
}
.hoverBox p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}
</style>